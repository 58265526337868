<template>
  <div>
    <ManageAward />
  </div>
</template>
<script>
import ManageAward from "@/components/ManageAward/ManageAward";
export default {
  components: {
    ManageAward,
  },
  created() {},
};
</script>
