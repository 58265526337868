<template>
  <div class="text-right">
    <v-dialog v-model="dialog" width="700px" height="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#2AB3A3"
          dark
          v-bind="attrs"
          v-on="on"
          style="height: 48px"
        >
          + เพิ่มรางวัล
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          เพิ่มรางวัล

          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อรางวัล (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="name_th"
                  dense
                  outlined
                  placeholder="ระบุชื่อรางวัล (TH)"
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อรางวัล (EN)</span>
                <v-text-field
                  hide-details
                  v-model="name_en"
                  dense
                  outlined
                  placeholder="ระบุชื่อรางวัล (EN)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผู้รับ (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="recipient_th"
                  dense
                  outlined
                  placeholder="ระบุชื่อผู้รับ (TH)"
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผู้รับ (EN)</span>
                <v-text-field
                  hide-details
                  v-model="recipient_en"
                  dense
                  outlined
                  placeholder="ระบุชื่อผู้รับ (EN)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ปีที่ได้รับ</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="years_received"
                  placeholder="ระบุปีที่ได้รับ"
                  dense
                  outlined
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"></v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">เนื้อหา (TH)</span>
                <span style="color: red">*</span>
                <quill-editor
                  class="quill233"
                  v-model="desc_th"
                  :content="content"
                  :options="editorOption"
                  @change="onEditorChange($event)"
                />
                <!-- <v-textarea
                  v-model="desc_th"
                  dense
                  outlined
                  auto-grow
                  counter="140"
                  placeholder="ระบุเนื้อหา (TH)"
                  :rules="rules.required"
                ></v-textarea> -->
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">เนื้อหา (EN)</span>
                <quill-editor
                  class="quill233"
                  v-model="desc_en"
                  :content="content1"
                  :options="editorOption"
                  @change="onEditorChange1($event)"
                />
                <!-- <v-textarea
                  v-model="desc_en"
                  dense
                  outlined
                  auto-grow
                  counter="140"
                  placeholder="ระบุเนื้อหา (EN)"
                ></v-textarea> -->
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-col cols="12" md="12" sm="12"
              ><span
                class="ml-n3"
                style="
                  width: 170px;
                  height: 26px;

                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 26px;
                "
                ><b>รูปปก</b></span
              >
              <span style="color: red">*</span>
            </v-col>
            <v-row>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="showImage">
                <v-img
                  :src="showImage"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="!showImage">
                <v-img
                  src="../../assets/noFile.png"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="9">
                <span>{{ namePic }}</span>
                <br />
                <span>ขนาดรูปที่แนะนำ : 1500 x 1500px</span>
                <br />
                <br />
                <br />
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="file"
                  ref="q_1_1"
                  v-on:change="submitfileMain('q_1_1')"
                />
              </v-col>
            </v-row>
            <v-col cols="12" md="12" sm="12" class="mt-5"
              ><span
                class="ml-n3"
                style="
                  width: 170px;
                  height: 26px;

                  font-family: 'IBM Plex Sans Thai';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 26px;
                "
                ><b>แกลลอรี่</b></span
              >
            </v-col>
            <v-row>
              <v-col
                v-for="(item, indexItemPic) in this.showImageUpload"
                :key="indexItemPic"
                class="d-flex child-flex"
                cols="3"
              >
                <v-img
                  :src="item.path"
                  contain
                  max-width="200px"
                  min-height="200px"
                >
                  <v-row>
                    <v-col>
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn
                        x-small
                        style="
                          border: solid 1px;
                          border-radius: 0px;
                          opacity: 0.4;
                        "
                        @click="pictureDelete(indexItemPic)"
                        >X</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <VueFileAgent
                  ref="vueFileAgent"
                  :theme="'grid'"
                  :multiple="true"
                  :deletable="true"
                  :meta="true"
                  :accept="'image/*'"
                  :helpText="'Choose images files or drag and drop here'"
                  :errorText="{
                    type: 'Invalid file type. Only images Allowed',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                  v-model="fileRecords"
                ></VueFileAgent>
              </v-col>
              <v-col cols="12" md="12" sm="12" class="text-center">
                <v-btn
                  :disabled="!fileRecordsForUpload.length"
                  @click="uploadFiles()"
                  color="#2AB3A3"
                  dark
                  x-large
                >
                  Upload {{ fileRecordsForUpload.length }} files
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                text
                style="border: solid 1px #d0d5dd; height: 44px"
                block
                @click="dialog = false"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                :loading="loading"
                @click="submit()"
                block
                dark
                color="#2AB3A3"
                style="height: 44px"
                >บันทึก</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      fileRecords: [],
      uploadUrl: "",
      uploadHeaders: { "X-Test-Header": "vue-file-agent" },
      fileRecordsForUpload: [],
      itemsUploadFile: [],
      showImageUpload: [],

      dialog: false,

      name_th: "",
      name_en: "",
      recipient_th: "",
      recipient_en: "",
      years_received: "",
      desc_en: "",
      desc_th: "",

      namePic: "",
      img_url: "",

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,

      file: [],
      levelItems: [],
      majorItems: [],

      content: "",
      content1: "",

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {},
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    cancel() {
      this.$refs.createForm.reset();
      this.dialog = false;
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        this.loading = true;
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        this.namePic = this.$refs[questionNo].files[0].name;
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);

        this.loading = false;
      }
    },
    async submit(pic) {
      if (this.$refs.createForm.validate(true)) {
        // await this.submitfileMain(pic);
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          name_th: this.name_th,
          name_en: this.name_en,
          recipient_th: this.recipient_th,
          recipient_en: this.recipient_en,
          years_received: this.years_received,
          desc_en: this.desc_en,
          desc_th: this.desc_th,
          img_url: this.img_url,
          pathPic: this.showImageUpload,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/awards`,
          data,
          // this.form,
          auth
        );
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$refs.createForm.reset();
          this.$refs.q_1_1.value = "";
          this.showImage = "";
          this.$emit("getAll");
          this.dialog = false;
          // this.$router.push("ManageBanners");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },

    async pictureDelete(val) {
      this.showImageUpload.splice(val, 1);
    },
    // async getUploadFile() {
    //   this.loading = true;
    //   const auth = {
    //     headers: { Authorization: `Bearer ${this.user.token}` },
    //   };
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/atmospheres?courseId=${this.courseId}`,
    //     auth
    //   );
    //   console.log("getUploadFile", response.data.data);
    //   this.itemsUploadFile = response.data.data;
    //   this.showImageUpload = response.data.data[0].pathPic;
    //   console.log("showImageUpload", this.showImageUpload);
    //   this.loading = false;
    // },
    async uploadFiles() {
      const auth = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let pathPic = [];
      pathPic = this.showImageUpload;

      for (let i = 0; i < this.fileRecords.length; i++) {
        let formData = new FormData();
        formData.append("files", this.fileRecords[i].file);
        formData.append("filename", this.fileRecords[i].file.name);
        formData.append("type", "uploadfile");

        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        this.showImageUpload.push({ path: response.data.data.path });
        console.log("this.showImageUpload", this.showImageUpload);
        console.log("fileRecords", this.fileRecords);
      }
      this.uploadFiles1(pathPic);
      this.fileRecords = [];
      this.fileRecordsForUpload = [];
    },
    async uploadFiles1(pathPic) {
      // console.log("itemsUploadFile", this.itemsUploadFile[0].id);
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        courseId: this.courseId,
        pathPic: pathPic,
        fileRecords: this.fileRecords,
      };
      if (this.itemsUploadFile.length) {
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/atmospheres/${this.itemsUploadFile[0].id}`,
          data,
          auth
        );
        console.log("uploadPathPic", response);
        console.log(response.data.response_status);
      } else {
      }
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>